@font-face {
	font-family: 'SF-Pro-Display-Regular';
	src: url('../assets/fonts/SFProDisplay-Regular.eot');
	src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
			url('../assets/fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
			url('../assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
			url('../assets/fonts/SFProDisplay-Regular.woff') format('woff'),
			url('../assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF-Pro-Display-Medium';
	src: url('../assets/fonts/SFProDisplay-Medium.eot');
	src: local('SF Pro Display Medium'), local('../assets/fonts/SFProDisplay-Medium'),
			url('../assets/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
			url('../assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
			url('../assets/fonts/SFProDisplay-Medium.woff') format('woff'),
			url('../assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'SF-Pro-Display-Semibold';
	src: url('../assets/fonts/SFProDisplay-Semibold.eot');
	src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
			url('../assets/fonts/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
			url('../assets/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
			url('../assets/fonts/SFProDisplay-Semibold.woff') format('woff'),
			url('../assets/fonts/SFProDisplay-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}