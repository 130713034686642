#download {
	padding: 180px 0 100px;
	.download-images {
		position: relative;
		text-align: center;
		overflow: hidden;
		&::before, &::after {
			content: '';
			position: absolute;
			z-index: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 290px;
			z-index: 1;
			background: $gradient;
			pointer-events: none;
		}
		&::before {
			bottom: -70%;
		}
		&::after {
			bottom: -85%;
		}
	}
	.download__image {
		margin: 0 auto;
		@include size(100%, 230px, 12px);
	}
	.download__image img {
		@include size(100%, 100%, 12px);
		object-position: top center;
	}
	.download-images__swiper {
		height: 230px;
	}
	.download-items__swiper-wrapper {
		margin-bottom: 52px;
		margin-top: -15px;
		position: relative;
	}
	.swiper-wrapper {
		align-items: baseline;
	}

	@include table-sm {
		.download-images {
			&::before {
				bottom: -43%;
			}
			&::after {
				bottom: -55%;
			}
		}
	}

	@include table-sm {
		.download__image {
			@include size(100%, 350px, 12px);
		}
		.download-images__swiper {
			height: 350px;
		}
	}

	@include table {
		padding: 200px 0 150px;
		.download__image {
			@include size(590px, 446px, 12px);
		}
		.download__swiper-wrapper {
			margin-bottom: 40px;
		}
  }

	@include desktop {
		padding: 358px 0 220px;
	}
	
	@include desktop-sm {
		.download-images__swiper {
			height: auto;
		}
	}

	@media screen and (min-width: 1500px) {
		.download-items__swiper-wrapper {
			margin-top: -62px;
		}
		.download__image {
			@include size(790px, 526px, 12px);
		}
		.download-images {
			&::before, &::after {
				height: 640px;
			}
			&::before {
				bottom: -20%;
			}
			&::after {
				bottom: -60%;
			}
		}
	}
}