@keyframes zoomOut1 {
	0% {
		transform: scale(1.7);
	}
	100% {
		transform: scale(1.5);
	}
}
@keyframes zoomOut2 {
	0% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1.3);
	}
}
@keyframes zoomOut3 {
	0% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1.1);
	}
}